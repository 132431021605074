import { combineReducers } from 'redux';
import layerDescriptionLoadedReducer from './layerDescriptionLoaded';
import layerTypeSelectedReducer from './layerTypeSelected';
import layerSelectedReducer from './layerSelected';
import layersLoadedReducer from './layersLoaded';
import playPauseReducer from './playPause';
import timeseriesLoadedReducer from './timeSeriesLoaded';
import visibleDateReducer from './visibleDate';
import timestampIndexReducer from './timestampIndex';

const allReducers = combineReducers( {
    play: playPauseReducer,
    layerType: layerTypeSelectedReducer,
    layer: layerSelectedReducer,
    layerDescription: layerDescriptionLoadedReducer,
    timeseries: timeseriesLoadedReducer,
    visibleDate: visibleDateReducer,
    timestampIndex: timestampIndexReducer,
    layers: layersLoadedReducer
})

export default allReducers;