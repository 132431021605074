const playPauseReducer = (state = null, action) => {
    switch(action.type) {
      case 'PLAY':
        return true;
      case 'PAUSE':
        return false;
      default:
        return state;
      }
  }

  export default playPauseReducer;