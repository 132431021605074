import { addMilliseconds, subMilliseconds, format, parse } from 'date-fns'
import React from 'react'
import { DatePicker } from "@material-ui/pickers";
import { connect } from "react-redux";
import { visibleDate, currentTimestampIndex } from '../actions';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import "./TimeSlider.css"

class TimeSelector extends React.Component {

    datePickerViews = ["year", "month"]
    minDate = new Date("2000-01-01")
    maxDate = new Date("2020-12-31")
    showDatePicker = false

    handleChange = date => {
        this.props.dispatch(visibleDate(date))
    }

    handleTimestampChange = event => {
        // console.log(event)
        // console.log(event.target.value)
        this.props.dispatch(currentTimestampIndex(event.target.value))
    }

    formatLabel = (date, invalidLabel) => {

        var dateFormat

        if (this.props.layer.id.endsWith('Y')) {
            dateFormat = 'yyyy'
        } else if (this.props.layer.id.endsWith('D')) {
            dateFormat = 'yyyy/MM/dd'
        } else {
            dateFormat = 'yyyy/MM'        }

        return format(date, dateFormat)
    }

    calculateDatePickerViews() {
        if (this.props.layer.id.endsWith('Y')) {
            this.datePickerViews = ["year"]
        } else if (this.props.layer.id.endsWith('M')) {
            this.datePickerViews = ["year", "month"]
        } else {
            this.datePickerViews = ["year", "month", "date"]
        }
    }

    calculateMinMaxDates() {
        if (!this.props.timeseries) {
            return
        }

        this.minDate = new Date(this.props.timeseries.startDate)
        this.maxDate = new Date(this.props.timeseries.endDate)

        if (this.minDate >= this.props.visibleDate) {
            //workaround - if date is equal than warning message is displayed
            const newVisibleDate = addMilliseconds(this.minDate, 1)
            this.props.dispatch(visibleDate(newVisibleDate))
        }

        if (this.maxDate < this.props.visibleDate) {
            //workaround - if date is equal than warning message is displayed
            const newVisibleDate = subMilliseconds(this.minDate, 1)
            this.props.dispatch(visibleDate(newVisibleDate))
        }

    }

    render() {
        var menuItems;
        if (this.props.timeseries) {
            menuItems = this.props.timeseries.timestamps.map((it, index) => {
                let timestamp = parse(it.split('/')[0].replace('T', '').replace('Z', ''), 'yyyyMMddHHmmss', new Date())
                const timestampWithOffset = new Date(timestamp.valueOf() - timestamp.getTimezoneOffset() * 60 * 1000);
                let formattedTimestamp = format(timestampWithOffset, "EE HH:mm")
                return <MenuItem value={index}>{formattedTimestamp}</MenuItem>
            });
        } else {
            menuItems = [<MenuItem value='loading'>Loading</MenuItem>];
        }
        return (
            <div>
                <div className="datePicker">
                    { this.showDatePicker ? 
                        <DatePicker
                            views={this.datePickerViews}
                            minDate={this.minDate}
                            maxDate={this.maxDate}
                            onChange={this.handleChange}
                            disabled={this.props.play}
                            autoOk={true}
                            value={this.props.visibleDate}
                            labelFunc={this.formatLabel}
                            InputProps={{ disableUnderline: true }}
                        /> 
                    : null }
                    
                </div>
                <div className="timestampSelector">
                    { !this.showDatePicker && this.props.timeseries ? 
                        <Select
                            disableUnderline={true}
                            value={this.props.timestampIndex}
                            onChange={this.handleTimestampChange}
                        >
                        {menuItems}
                        </Select>
                    : null }
                </div>
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.layer !== this.props.layer) {
            this.calculateDatePickerViews()
        }

        if (prevProps.timeseries !== this.props.timeseries) {
            this.showDatePicker = (this.props.timeseries.timestamps === undefined || this.props.timeseries.timestamps === null || this.props.timeseries.timestamps.legth ===0)

            this.forceUpdate()
            
            this.calculateMinMaxDates()
        }

    }
}


const mapStateToProps = (state) => {
    return {
      play: state.play,
      layer: state.layer,
      layerDescription: state.layerDescription,
      timeseries: state.timeseries,
      visibleDate: state.visibleDate,
      timestampIndex: state.timestampIndex,
    }
  }
  
export default connect(mapStateToProps)(TimeSelector)
