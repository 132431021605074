import axios from "axios";

export const playAction = () => {
    return {
      type: 'PLAY'
    }
  }
  
export const pauseAction = () => {
    return {
      type: 'PAUSE'
    }
  }  

  export const layerTypeChanged = (layerType) => {
    return (dispatch) => {
      axios.get('https://api.elastictiles.com/layer?clientId=expectedWeather')
      // axios.get('https://api.elastictiles.com/layer?type=' + layerType)
      .then(res => {
        dispatch(layerTypeSelected(layerType))
        dispatch(layersLoaded(res.data))
      })
    }
  }    

  export const layerTypeSelected = (layerType) => {
    return {
      type: 'LAYER_TYPE_SELECTED',
      payload: layerType
    }
  }    


export const layerSelected = (layer) => {
    return {
      type: 'LAYER_SELECTED',
      payload: layer
    }
  }    

export const layerDescriptionLoaded = (layerDescription) => {
    return {
      type: 'LAYER_DESCRIPTION_LOADED',
      payload: layerDescription
    }
  }    

export const fetchLayerDesctiption = (layer) => {
    return (dispatch) => {
      axios.get('https://api.elastictiles.com/description/' + layer)
      .then(res => {
        dispatch(fetchTimeseries(layer))
        dispatch(layerDescriptionLoaded(res.data))
      })
    }
  }      

export const timeSeriesLoaded = (timeseries) => {
    return {
      type: 'TIME_SERIES_LOADED',
      payload: timeseries
    }
  }    

export const fetchTimeseries = (layer) => {
    return (dispatch) => {
      axios.get('https://api.elastictiles.com/timestamps/' + layer)
      .then(res => {
        dispatch(timeSeriesLoaded(res.data))
      })
    }
  }        

export const visibleDate = (date) => {
    return {
      type: 'DATE_CHANGED',
      payload: date
    }
  }    

export const currentTimestampIndex = (date) => {
    return {
      type: 'TIMESTAMP_CHANGED',
      payload: date
    }
  }    


export const layersLoaded = (layers) => {
    return {
      type: 'LAYERS_LOADED',
      payload: layers
    }
  }    
