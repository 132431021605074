import React from 'react'
import { connect } from "react-redux";
import { layerSelected } from "../actions";
import { IconContext } from "react-icons";
import { FaInfoCircle } from 'react-icons/fa';
import ReactTooltip from "react-tooltip";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import './LayerSelector.css';

class LayerSelector extends React.Component {

 
    render() {
        var menuItems;
        if (this.props.layers) {
            menuItems = this.props.layers.map((d) => <MenuItem value={d}>{d.name}</MenuItem>);
        } else {
            menuItems = [<MenuItem value='loading'>Loading</MenuItem>];
        }

        return (
            <div>
                <div className='layerSelector'>
                    <Select
                        disableUnderline={true}
                        value={this.props.layer}
                        onChange={(event) => this.props.dispatch(layerSelected(event.target.value))}
                    >
                    {menuItems}
                    </Select>
                </div>
                <div>
                    <button type="button" data-tip data-for="registerTip" className='layerDescriptionButton'>
                        <IconContext.Provider value={{ color: 'white', size: '20px' }}>
                            <FaInfoCircle /> 
                        </IconContext.Provider>
                    </button>
                    <ReactTooltip id="registerTip" place="right" data-event="click focus" effect="solid">
                        {this.props.layerDescription ? this.props.layerDescription.licenseInformation.description : ""}
                    </ReactTooltip>
                </div>
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    
        if (prevProps.layers !== this.props.layers) {
            this.props.dispatch(layerSelected(this.props.layers[0]));
        }
    }

}


const mapStateToProps = state => {
    return {
        layerDescription: state.layerDescription,
        layerType: state.layerType,
        layer: state.layer,
        layers: state.layers
    }
}

export default connect(mapStateToProps)(LayerSelector)