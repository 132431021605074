import React from 'react'
import { connect } from "react-redux";

import LegendItem from './LegendItem'
import './Legend.css'

class Legend extends React.Component {

    render() {
        return (
            this.props.layerDescription ?
                <div className='legendStyle'>
                    {
                        this.calculateNewLegend(this.props.layerDescription.legend).map(legendItem => {
                            return <LegendItem key={legendItem.from} legendElement={legendItem} />
                        })
                    }
                </div>
            :
                <div className='legendStyle'></div>
        )
    }

    calculateNewLegend(legend) {
        let legendItems = []

        legendItems.push( { from: legend.unit, color: legend.values[0].color })
        legend.values.map(legendItem => legendItems.push( { from: legendItem.from, color: legendItem.color } ))

        return legendItems
    }

}

const mapStateToProps = state => {
    return {
        layerDescription: state.layerDescription
    }
}

export default connect(mapStateToProps)(Legend)