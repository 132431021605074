import React from 'react'
import mapboxgl from 'mapbox-gl';
import { connect } from "react-redux";
import { layerTypeChanged, fetchLayerDesctiption, fetchTimeseries, visibleDate, currentTimestampIndex } from "../actions";
import { format, parse, isSameDay, isSameMonth, isSameYear, addDays, addMonths, addYears, isThisSecond } from 'date-fns'
import { convertToTimeZone } from 'date-fns-timezone'
import {isMobile} from 'react-device-detect';
import './Map.css'

mapboxgl.accessToken = 'pk.eyJ1IjoiamF3b3Jza2l3b2oiLCJhIjoiY2pldG1zYjBwMDI2YTJ4bnpnbjBrYTJ6bSJ9.DXTExrussJkU_JxtpLPSzg';


class Map extends React.Component {
  timestamps = null
  url = null
  layers = {};
  curTimestampIndex = -1;
  cacheForward = 2;
  cacheBackward = 1;
  opacityValue = 0.5;
  map = null
  interval = null
  firstSelectedLayer = 'VECTOR.gfs_0.25_air_temperature_2m.default';
  initialLongitiude = isMobile ? 20 : 24
  initialLatitude = isMobile ? 55 : 50
    
  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      // style: 'mapbox://styles/mapbox/satellite-v9',
      center: [this.initialLongitiude, this.initialLatitude],
      zoom: 3.9,
      attributionControl: false,
      logoPosition: 'top-left',
      transformRequest: (url, resourceType) => {
        return {
          url: url,
          headers: { 'Accept': 'image/png' }
        }
      },
    });
    this.map.addControl(new mapboxgl.AttributionControl({ compact: true }), 'top-right');

    this.props.dispatch(layerTypeChanged('VECTOR_POLYGON'));
    this.props.dispatch(fetchLayerDesctiption(this.firstSelectedLayer));
    // this.props.dispatch(fetchTimeseries(this.firstSelectedLayer));

  }

  render() {
    return (
      <div>
        <div ref={el => this.mapContainer = el} className='mapContainer' />
      </div>
    )
  }

  selectLayer(timeSeriesDescription) {

    if (this.timestamps) {
      for (let i = 0; i < this.timestamps.length; i++) {
        const timestamp = this.timestamps[i];
        this.removeLayer(timestamp);
      }
    }

    this.timestamps = this.calculateTimestamps(timeSeriesDescription);
    this.calculateCurrrentTimestampIndex();
    this.url = this.calculateUrl(timeSeriesDescription);
  
    // this.curTimestampIndex = 0
    // this.props.dispatch(currentTimestampIndex(this.curTimestampIndex))
    this.addLayers();
    
    // const newTimestamp = this.timestamps[this.curTimestampIndex];
    // this.addLayer(newTimestamp);
    // this.map.setPaintProperty(newTimestamp, this.getOpacityPaintProperty(), this.opacityValue);
  }

  calculateCurrrentTimestampIndex() {
    if (this.curTimestampIndex == -1) {
      const currentDate = new Date()
      const currentTime = currentDate.valueOf() + currentDate.getTimezoneOffset() * 60 * 1000;

      let minIndex = 0;
      let minValue = currentTime;

      for (let i = 0; i < this.timestamps.length; i++) {
        const timestamp = this.timestamps[i];
        const timestampTime = parse(timestamp.split('/')[0].replace('T', '').replace('Z', ''), 'yyyyMMddHHmmss', new Date()).valueOf()

        const diff = Math.abs(currentTime - timestampTime);
        if (diff <= minValue) {
          minValue = diff;
          minIndex = i;
        }
      }

      this.curTimestampIndex = minIndex;
      this.props.dispatch(currentTimestampIndex(this.curTimestampIndex));
    }

  }

  calculateTimestamps(timeSeriesDescription) {
    if (timeSeriesDescription.timestamps != null) {
      return timeSeriesDescription.timestamps;
    }

    let result = []

    const startDate = new Date(timeSeriesDescription.startDate)
    const endDate = new Date(timeSeriesDescription.endDate)


    let currentDate = convertToTimeZone(startDate, { timeZone: 'UTC' })

    do {
      result.push(format(currentDate, "yyyyMMdd'T'HHmm'Z'"))
      currentDate = this.addTime(currentDate)
    } while (currentDate.getTime() < endDate.getTime())

    return result
  }

  addTime(date) {
    const unit = this.props.timeseries.interval.unit
    const value = this.props.timeseries.interval.value

    let result

    if (unit === 'year') {
      result = addYears(date, value)
    } else if (unit === 'month') {
      result = addMonths(date, value)
    } else {
      result = addDays(date, value)
    }

    return result
  }

  calculateUrl(timeSeriesDescription) {
    return timeSeriesDescription.url;
  }

  addLayer(timestamp) {
    if (this.layers[timestamp]) {
      return;
    }
  
    // eslint-disable-next-line
    const url = this.url.replace('${timestamp}', timestamp);

    const layerDescription = this.props.layerDescription

    if (layerDescription.type === 'fill') {
      const vectorLayer = {
          'id': timestamp.toString(),
          'type': 'fill',
          'source': {
              "type": "vector",
              tiles: [url],
              "minzoom": layerDescription.minZoom,
              "maxzoom": layerDescription.maxZoom
          },
          "source-layer": "vector",
          'paint': {
              'fill-opacity': 0.0,
              'fill-color': layerDescription.style.paint['fill-color'],
              'fill-opacity-transition': {
                "duration": 0,
                "delay": 0
              }
              // 'fill-color': ['get', 'fill-color'],
          },
      };
      this.map.addLayer(vectorLayer);

      // this.map.on('click', timestamp.toString(), (e) => {
      //     // console.log(e)

      //     let text = e.features[0].properties.value;
      //     if (e.features[0].properties.tile) {
      //       text = text + '\n' + e.features[0].properties.tile
      //     }
      //     // console.log(text)

      //     new mapboxgl.Popup()
      //       .setLngLat(e.lngLat)
      //       .setHTML(text)
      //       .addTo(this.map);
      //   });
        
      // this.map.on('mouseenter', timestamp.toString(), () => {
      //   this.map.getCanvas().style.cursor = 'pointer';
      // });

      // this.map.on('mouseleave', timestamp.toString(), () => {
      //   this.map.getCanvas().style.cursor = '';
      // });
    
      this.layers[timestamp] = vectorLayer;
    } else if (layerDescription.type === 'raster') {
      const vectorLayer = {
          'id': timestamp.toString(),
          'type': 'raster',
          'source': {
              "type": "raster",
              tiles: [url],
              "minzoom": layerDescription.minZoom,
              "maxzoom": layerDescription.maxZoom
          },
          "source-layer": "raster",
          'paint': {
              'raster-opacity': 0.0,
              'raster-opacity-transition': {
                "duration": 0,
                "delay": 0
              }
          },
      };
      this.map.addLayer(vectorLayer);
    
      this.layers[timestamp] = vectorLayer;
    } else if (layerDescription.type === 'line') {
      const vectorLayer = {
          'id': timestamp.toString(),
          'type': 'line',
          'source': {
              "type": "vector",
              tiles: [url],
              "minzoom": layerDescription.minZoom,
              "maxzoom": layerDescription.maxZoom
          },
          "source-layer": "vector",
          'paint': {
              'line-opacity': 0.0,
              'line-opacity-transition': {
                "duration": 0,
                "delay": 0
              }
          },
      };
      this.map.addLayer(vectorLayer);
    
      this.layers[timestamp] = vectorLayer;
    } else {
      const vectorLayer = {
          'id': timestamp.toString(),
          'type': 'symbol',
          'source': {
              "type": "vector",
              tiles: [url],
              "minzoom": layerDescription.minZoom,
              "maxzoom": layerDescription.maxZoom
          },
          "source-layer": "vector",
      };

      // console.log(layerDescription.style);
      // const layerStyle = JSON.parse(layerDescription.style);
      const layerStyle = layerDescription.style;
      const opacity = {
        'paint': {
          'icon-opacity': 0.0,
          'icon-opacity-transition': {
            "duration": 0,
            "delay": 0
          },
          'text-opacity': 0.0,
          'text-opacity-transition': {
            "duration": 0,
            "delay": 0
          }
        }
      }
      const mergedLayer = {...vectorLayer, ...layerStyle, ...opacity};

      this.map.addLayer(mergedLayer);
    
      this.layers[timestamp] = mergedLayer;
    }
  };

  getOpacityPaintProperty() {
    const layerDescription = this.props.layerDescription

    if (layerDescription.type === 'symbol') {
      return 'text-opacity';
    } else {
      return layerDescription.type + '-opacity';
    }
  }

  getOpacityValue() {
    const layerDescription = this.props.layerDescription

    if (layerDescription.type === 'symbol') {
      return 1;
    } else {
      return this.opacityValue;
    }
  }
  
  removeLayer(timestamp) {
    if (!this.layers[timestamp]) {
      return;
    }
    this.map.removeLayer(timestamp);
    this.map.removeSource(timestamp);
    delete this.layers[timestamp];
  };

  addLayers() {
    const backwardIndex = Math.max(this.curTimestampIndex - this.cacheBackward, 0);
    const forwardIndex = Math.min(this.curTimestampIndex + this.cacheForward, this.timestamps.length - 1);

    for (let i = 0; i < this.timestamps.length; i++) {
      const timestamp = this.timestamps[i];

      if (i >= backwardIndex && i <= forwardIndex) {
        this.addLayer(timestamp);
        if (i === this.curTimestampIndex) {
          this.map.setPaintProperty(timestamp, this.getOpacityPaintProperty(), this.getOpacityValue());
        } else {
          this.map.setPaintProperty(timestamp, this.getOpacityPaintProperty(), 0.0);
        }
      }
    }
  }

  nextTimestamp() {
    if (this.curTimestampIndex == this.timestamps.length - 1) {
      this.curTimestampIndex = 0;
    } else {
      this.curTimestampIndex++;
    }
    this.props.dispatch(currentTimestampIndex(this.curTimestampIndex));
    
    this.updateLayerTimestamps();

  }

  updateLayerTimestamps() {
    var backwardIndex = this.curTimestampIndex - this.cacheBackward;
    if (backwardIndex < 0) {
      backwardIndex = this.timestamps.length - 1 - this.cacheBackward;
    }
    const forwardIndex = (this.curTimestampIndex + this.cacheForward) % this.timestamps.length;


    for (let i = 0; i < this.timestamps.length; i++) {
      const timestamp = this.timestamps[i];


      if ((backwardIndex < forwardIndex && (i < backwardIndex  || i > forwardIndex)) || (backwardIndex > forwardIndex && (i < backwardIndex  && i > forwardIndex))) {
        this.removeLayer(timestamp);
      } else {
        this.addLayer(timestamp);
      }

      if (this.layers[timestamp]) {
        if (i === this.curTimestampIndex) {
          this.map.setPaintProperty(timestamp, this.getOpacityPaintProperty(), this.getOpacityValue());
        } else {
          this.map.setPaintProperty(timestamp, this.getOpacityPaintProperty(), 0.0);
        }
      }
    }
  }

  selectTimestamp(date) {
    let timestampIndex = this.findTimestamp(date)
    if (timestampIndex !== -1) {
      this.selectTimestampIndex(timestampIndex)
    }
  }

  selectTimestampIndex(timestampIndex) {
    this.curTimestampIndex = timestampIndex

    this.updateLayerTimestamps();
  }

  findTimestamp(date) {
    for (const [i, value] of this.timestamps.entries()) {
      let timestamp = parse(value.replace('T', '').replace('Z', ''), 'yyyyMMddHHmm', new Date())
      if (
           (this.props.layer.id.endsWith('Y') && isSameYear(timestamp, date))
        || (this.props.layer.id.endsWith('M') && isSameMonth(timestamp, date))
        || (this.props.layer.id.endsWith('D') && isSameDay(timestamp, date))
        ) {

        return i
      }
    }

    return -1
  }

  toggleAnimation() {
    if (!this.map) {
      return
    }

    if (this.props.play) {
      this.interval = setInterval(this.nextTimestamp.bind(this), 750)
    }

    if (!this.props.play) {
      clearInterval(this.interval)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
    if (prevProps.play !== this.props.play) {
      this.toggleAnimation()
    }
    
    if (prevProps.layer !== this.props.layer) {
      if (prevProps.layer && prevProps.layer !== undefined) {
        const isRadarPrev = prevProps.layer.id.startsWith('radar_')
        const isRadarCurrent = this.props.layer.id.startsWith('radar_')
        if (isRadarPrev != isRadarCurrent) {
          this.curTimestampIndex = -1;
        }
      }
      
      this.fetchMetadata(this.props.layer)
    }

    if (prevProps.timeseries !== this.props.timeseries) {
      if (this.props.layerDescription) {
        this.selectLayer(this.props.timeseries)
      }
    }

    if (prevProps.visibleDate !== this.props.visibleDate) {
      this.selectTimestamp(this.props.visibleDate)
    }

    if (prevProps.timestampIndex !== this.props.timestampIndex) {
        this.selectTimestampIndex(this.props.timestampIndex);
    }

  }

  fetchMetadata(layer) {
    this.props.dispatch(fetchLayerDesctiption(layer.id));
  }


}

const mapStateToProps = (state) => {
  return {
    play: state.play,
    layer: state.layer,
    layerDescription: state.layerDescription,
    timeseries: state.timeseries,
    visibleDate: state.visibleDate,
    timestampIndex: state.timestampIndex,
    layers: state.layers
  }
}

export default connect(mapStateToProps)(Map)