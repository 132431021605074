import React from 'react'
import { connect } from "react-redux";
import { layerTypeChanged } from "../actions";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import './Settings.css';

class Settings extends React.Component {

 
    render() {
        return (
            <div>
                <div className='layerType'>
                    <Select
                        disableUnderline={true}
                        value={this.props.layerType}
                        onChange={(event) => this.props.dispatch(layerTypeChanged(event.target.value))}
                    >
                        <MenuItem value={'VECTOR_POLYGON'}>Polygons</MenuItem>
                        {/* <MenuItem value={'VECTOR_POINT'}>Point</MenuItem> */}
                        {/* <MenuItem value={'VECTOR_LINE'}>Line</MenuItem> */}
                        {/* <MenuItem value={'RASTER_POLYGON'}>Raster</MenuItem>
                        <MenuItem value={'RASTER_SMOOTH'}>Smooth</MenuItem> */}
                        {/* <MenuItem value={'VECTOR_TEST'}>Test</MenuItem> */}
                    </Select>
                </div>
            </div>
        )
    }


   
}


const mapStateToProps = state => {
    return {
        layerDescription: state.layerDescription,
        layerType: state.layerType,
        layer: state.layer,
        layers: state.layers
    }
}

export default connect(mapStateToProps)(Settings)