import React from 'react'

export default function LegendItem( { legendElement }) {
    
    let styles = {
        backgroundColor: legendElement.color,
      };

    return (
        <div className="legendItem" style={styles}>
            {legendElement.from}
        </div>
    )
}


