import React from 'react'
import TimeSelector from './TimeSelector'
import ControlButtons from './ControlButtons'


export default function TimeSlider() {
    return (
        <div>
                <TimeSelector />
                <ControlButtons />
        </div>
    )
}